import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefresh from "../hooks/useRefresh";
import useAtuh from "../hooks/useAuth";
import useLocalStorage from "../hooks/useLocalStorage";

const PresistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefresh();
    const { auth } = useAtuh();
    const [presist] = useLocalStorage('presist', false);

    useEffect(() => {
        let isMounted = true;
        const verifyRefreshToken = async () => {
            try {
                await refresh();
            } catch (error) {
                console.log(error);
            } finally {
                isMounted && setIsLoading(false);
            }
        }
        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

        return () => isMounted = false;
    }, []);

    useEffect(() => {
        console.log(`isLoading: ${isLoading}`);
        console.log(`accessToken: ${JSON.stringify(auth?.accessToken)}`);
    }, [isLoading]);

    return (
        <>
            {
                !presist
                    ? <Outlet />
                    : isLoading
                        ? <p>Loading ...</p>
                        : <Outlet />
            }
        </>
    )
}

export default PresistLogin;