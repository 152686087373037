import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAtuh from "../hooks/useAuth";
import { jwtDecode } from "jwt-decode";

const RequireAuth = () => {
    const { auth } = useAtuh();
    const location = useLocation();

    const decode = auth?.accessToken
        ? jwtDecode(auth.accessToken)
        : undefined

    return (
        auth?.user
            ? <Outlet />
            : <Navigate to='/login' state={{ from: location }} replace />
    )
}

export default RequireAuth
